import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Boy Scout Sex Abuse Lawyer CT",
    heroH1: "Boy Scout Sex Abuse Lawyer",
    heroH2: "When experience, integrity, and results matter.",
    description:
      "If you or someone you know has been sexually abused by a Boy Scout leader, contact attorney Frank Bartlett. We provide a voice for victims. Call us to seek justice.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>CT Boy Scout Sex Abuse Attorney</h2>
            <p className="mb-8">
              Boy Scout sexual abuse attorney Frank Bartlett sees a common
              pattern from his clients. When adults are put into an
              authoritative role over young children, they have an obligation to
              honor the trust placed in them. Scoutmasters with the Boy Scouts
              of America exploiting that control through acts of sexual abuse
              does more than just shatter trust. Their predatory practices also
              change the lives of victims immediately and for years into the
              future. The Boy Scouts of America is a high-profile organization
              with a history that goes back nearly an entire century. However, a
              significant portion of that history involves sexual abuse of Boy
              Scouts by scoutmasters during camping trips and other Boy Scout
              functions. Traditionally, these predators are skilled in locating
              organizations and authoritative positions where they can commit
              crimes against children and then threaten or bribe them into
              silence. Boy Scouts who have been sexually abused at an early age
              by CT Scout Leaders such as Alton Parady in Southern CT, David
              Kress of Thompson, CT or David (Dirk) Davenport of Madison, CT
              should contact us now. If you or anyone you know has been abused
              please call our firm.
            </p>
            <h2>
              Forensic Investigation of Boys Scouts of America Reveals
              Widespread Sexual Abuse
            </h2>
            <p className="mb-8">
              2019 – An expert who analyzed the Boy Scouts of America’s
              “ineligible volunteer” or “perversion files,” found that over
              7,800 volunteers and leaders within the organization had been
              credibly accused of utilizing the organization to sexually abuse
              children. She further found that these volunteers and leaders had
              abused more than 12,000 victims between 1944-2016.
            </p>
            <p className="mb-8">
              Dr. Jane Warren, a professor at the University of Virginia, led a
              team contracted by the Boy Scouts of America to conduct a forensic
              investigation into the prevalence of sexual abuse within the
              organization. The culmination of this 5-year examination of
              internal documents was the discovery of a pattern of rampant
              sexual abuse, child endangerment, and systemic failure within the
              BSA that has infected communities across the country for decades.
            </p>
            <p className="mb-8">
              Warren’s findings became a matter of public record shortly after
              she testified as part of a child abuse trial in Minnesota. This
              list of perpetrators is believed to be the largest database of
              offenders ever compiled in the organization’s 109-year history. Of
              the 7,800 perpetrators listed, at least 150 were involved with
              scout troops in New York and 35 in Connecticut.
            </p>
            <p className="mb-8">
              This newly-revealed evidence of child abuse within the Boy Scouts
              of America comes on the eve of the effective date of the
              pro-victim New York Child Victim’s Act. This legislative victory
              extends the statute of limitations for victims to pursue civil
              claims for child sexual abuse and eliminates reporting roadblocks
              for victims. It also includes a one-year “revival window” that
              will allow all victims to pursue claims for child sexual abuse,
              without regard to the current statute of limitations. This
              one-time, one-year opportunity for all NY victims to seek justice
              against any abuser begins August 14, 2019.
            </p>
            <p className="mb-8">
              Connecticut’s state government is in the process of following New
              York’s powerful example, as they have already proposed a bill to
              extend and eliminate many of the statutes regarding civil and
              criminal charges. This bill would include a 27-month
              “revival-window” for all victims to come forward and was
              necessitated by disturbing reports of parish abuse within the
              state’s largest Catholic communities.
            </p>
            <p className="mb-8">
              Like New York and Connecticut, many other states are currently
              reassessing their statutes of limitation relating to sexual abuse.
              This re-evaluation represents a long-overdue legal shift from
              protecting institutions to advocating for individual victim’s
              rights. Statistics show that the average sexual abuse victim does
              not report their abuse until they are 52 years old. Eliminating
              safeguards for sexual abusers, like statutes of limitation, will
              allow victims to come forward to pursue legal action to obtain the
              justice they deserve at a point in their life that they have the
              maturity and confidence to face this difficult ordeal.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <h2>
              Connecticut Personal Injury Attorney – Holding Sexual Predators
              Responsible
            </h2>
            <p className="mb-8">
              In representing adult victims of abuse who were former boy scouts,
              Cheshire Boy Scout sexual abuse lawyer Frank Bartlett Jr. pursues
              actions against the national corporate organization and the local
              council. He conducts in-depth investigations to identify the
              knowledge the organization or organizations possessed about the
              scoutmaster’s propensities for sexual abuse. Attorney Bartlett is
              a recognized leader in this aspect of the law and has been quoted
              on the topic of BSA Scoutmaster’s sexual abuse in several national
              publications such as the Miami Herald, L.A. Times, and has been
              interviewed on all Connecticut major TV News stations.
            </p>
            <h2>TV News and Press about sexual abuse in Scouting</h2>
            <p className="mb-8">
              10/18/2012 WFSB TV HARTFORD, CT –Nearly two decades worth of
              secret files about suspected Boy Scout molestation has been
              released, including men from Connecticut. –
            </p>
            <p className="mb-8">
              06/15/2012 Attorney Bartlett quoted in{" "}
              <strong>Los Angeles Times</strong> about sexual abuse cases
              against Boy Scouts of America:{" "}
              <a
                href="https://articles.latimes.com/2012/jun/14/nation/la-na-scouts-20120615"
                target="_blank"
                rel="noreferrer"
              >
                Read more here.
              </a>
            </p>
            <p className="mb-8">
              05/31/12 <strong>New Haven Register</strong> Two more former
              Madison Boy Scouts sue over sex abuse document:{" "}
              <a
                href="https://www.nhregister.com/news/article/2-more-former-Madison-Boy-Scouts-sue-over-sex-11439958.php"
                target="_blank"
                rel="noreferrer"
              >
                Read more here.
              </a>{" "}
            </p>
            <p className="mb-8">
              02/22/2012 WVIT 30 Boy Scout Molestation Case brought on behalf of
              clients by Frank Bartlett Jr.{" "}
              <a
                href="https://www.nbcconnecticut.com/news/local/boy-scouts-sexual-abuse-molestation/1923631/"
                target="_blank"
                rel="noreferrer"
              >
                Read more here.
              </a>{" "}
            </p>
            <p className="mb-8">
              02/21/2012 News conference at the Omni Hotel in New Haven. Covered
              by local TV stations, Attorney Frank Bartlett of Bartlett Legal
              Group in Cheshire CT and Attorney Kelly Clark of Portland, Oregon
              announce a lawsuit against the Boy Scouts of America and the
              Connecticut Yankee Council of the Boy Scouts saying they knew or
              should have known that convicted abuser David “Dirk” Davenport had
              molested boys in Minnesota and Nebraska prior to his activities in
              Connecticut. Attorney Clark passed away in December of 2013.
              Attorney Bartlett was very fortunate to have worked with Attorney
              Clark, one of the nation’s leaders in helping eliminate sexual
              abuse in scouting. Attorney Clark was co-counsel with Attorney
              Bartlett on several Boy Scouts of America abuse cases in
              Connecticut. For over thirty years Attorney Clark represented
              hundreds of child molestation victims in litigation against what
              he called “institutions of trust” — the Catholic Church, and the
              Boy Scouts of America. He helped force mammoth and beloved
              organizations to concede to decades of sexual abuse within their
              ranks, or to efforts to keep knowledge of abuse secret from the
              public. The knowledge Attorney Bartlett gained by working with
              Attorney Clark has been invaluable in representing sexually abused
              Boy Scouts here in Connecticut.
            </p>

            <h2>What to expect at your initial consultation</h2>
            <p className="mb-8">
              Clients who retain our firm receive the validation they need at
              the initial, confidential consultation. In addition to reviewing
              timelines of the abuse and locations where sexual molestation
              occurred, clients are directed to literature that includes Scout’s
              Honor, a book that covers abuse that occurred to other boy scouts.
              They are given the needed reassurance that they are not at fault
              nor are they alone. Legal action may not be the first step.
              Getting the treatment and counseling you need may be necessary to
              get certain issues under control. Civil actions can take place
              following the criminal process or concurrently with the criminal
              process, although the presentation of criminal charges against the
              perpetrator is not a necessary precursor to filing a civil action.
            </p>
            <h3>
              We represent clients with sexual abuse by Boy Scout leaders,
              priests, medical professionals, teachers and others
            </h3>
            <ul className="list-disc list-outside ml-6">
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Sexual abuse home page
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/sexual-abuse-by-priests/">
                  Priest
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Family member, foster parent
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/ct-boy-scout-sexual-abuse-lawyer/">
                  Boy Scout leader
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Teacher
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
